import React from 'react';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../../routes';

import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import { isMobile } from '../../util/style-utils';
import PgeButton from '../../components/pge-button/_PgeButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    root: {
      marginTop: 32,
      marginBottom: 32,
    },
    rootContainer: {
      display: 'flex',
      gap: '20px',
      flexDirection: 'column',
      padding: 50,
      backgroundColor: colors.riverBlue,
    },
    imageWrapper: {
      flex: '0.1',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
      },
    },
    image: {
      width: '514px',
      height: '325px',
      border: `10px solid ${colors.white}`,
      outline: 'solid 1px #000',
      [theme.breakpoints.down('xs')]: {
        width: '424px',
        height: '235px',
      },
    },
    messageWrapper: {
      flex: '1.1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& h1': {
        fontSize: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        flex: '1',
      },
    },
    errorItem: {
      color: colors.noirBlur,
      paddingTop: 10,
    },
    returnButton: {
      marginTop: 20,
      width: 'max-content',
    },
  }),
);

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container className={classes.rootContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Grid item className={classes.imageWrapper}>
              <img
                alt=""
                className={classes.image}
                src={require('../../images/line-crew.jpg')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm className={classes.messageWrapper}>
            <Typography variant={'h2'} component={'h1'} className={classes.errorItem}>
              {t('TECHNICAL_ISSUES')}
            </Typography>
            <Typography variant={'body1'} className={classes.errorItem}>
              {t('PLEASE_TRY_AGAIN')}
            </Typography>
            <Typography variant={'body1'} className={classes.errorItem}>
              {t('HOURS_OF_OPERATION')}
            </Typography>
            <PgeButton
              className={classes.returnButton}
              data-testid="button"
              theme="Primary"
              fullWidth={isMobile()}
              onClick={() => {
                return navigate(ROUTES.HOME);
              }}
            >
              {t('RETURN_TO_HOME_PAGE')}
            </PgeButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
